.custom-row .ant-table-cell,
.row-top-border .ant-table-cell,
.total-calculated .ant-table-cell {
  border-inline-end: none !important;
  border-inline-start: none !important;
  border-bottom: none !important;
}

.row-top-border .ant-table-cell {
  border-top: 3px solid #000000 !important;
}

.custom-row .ant-table-cell {
  border-top: none !important;
}

.total-calculated .ant-table-cell {
  font-weight: bold;
  color: #32c5d2;
}

.full-width-column .ant-table-cell {
  font-weight: bold;
  background-color: #ffffff;
}
