.data-row {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.data-row-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data-column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-row-footer {
  border-top: 1px solid #000;
  margin-top: 10px;
}
