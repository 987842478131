.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 30px;
  margin: 20px;
  background-image: url('../public/header-logo.png');
  background-size: cover;
}

.site-layout .site-layout-background {
  background: #fff;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

p {
  padding: 0;
  margin: 0;
}

.table-row-light {
  background-color: #d3d3d3;
}
.table-row-light:first-child > td {
  border-top: solid 1.5px #ccc !important;
}
.table-row-dark {
  background-color: #fff;
}
.table-row-white {
  background-color: #fff;
}
.table-row-dark:hover > td,
.table-row-light > td {
  background-color: unset !important;
}

.bg-header-white .ant-table-thead .ant-table-cell {
  background-color: white;
  color: #32c5d2;
}
.table-row-white > td {
  border-top: solid 1.5px black !important;
}

.alert-info {
  padding: 10px;
  background: #d9edf7;
  color: #31708f;
  font-weight: bold;
}

/* Hover the table row */
.hover-row .ant-table-tbody > tr:hover > td {
  background-color: rgba(116, 243, 197, 0.3) !important;
}
