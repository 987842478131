.container {
  padding: 10px; /* Optional for spacing */
  text-align: left; /* Align text to the left within the container */
}

.content {
  display: block; /* Makes the first div a block element */
  margin-bottom: 10px; /* Optional margin for spacing between divs */
}

.button {
  text-align: right; /* Align the button to the right within its div */
}

.custom-descriptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom-descriptions-item {
  flex-basis: 50%; /* Adjust this value as needed */
  margin-left: 20px; /* Adjust the left margin as needed */
}
