.auth-container {
  height: 400px;
  width: 450px;
  background-color: #dfdcdc !important;
  margin: auto;
}

.auth-container .auth-container-header {
  text-align: left;
  background: #fff;
  padding: 1px 20px;
  border: #022f3b;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: #7761a7;
}

.auth-container .auth-container-header h1 {
  font-size: 20px;
  color: #022f3b;
  margin: 0px;
  padding: 12px;
  font-weight: 700;
}

.auth-container .auth-container-header p {
  font-weight: 600;
  color: #645d5d;
}

.auth-container .auth-container-footer {
  display: flex;
  justify-content: space-between;
  background: #f0f0f0;
}

.auth-container .auth-container-body {
  background: #f0f0f0;
  padding: 10px 25px 1px 25px;
}

.bold-text-uppercase {
  margin: 0px;
  font-weight: 700;
  text-transform: uppercase;
}

.bold-text {
  margin: 0px;
  font-weight: 700;
}

.auth-container .ant-form-item {
  margin: 10px 0px;
}

.auth-container .ant-input {
  padding: 5px !important;
}

.auth-container .tandc {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}
