.uppercase {
  text-transform: uppercase;
}

.DataRow-title {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 22px;
  justify-content: center;
  align-items: center;
}

.DataRow-column,
.DataRow-footer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.DataRow-column {
  height: 22px;
}

.column-name,
.column-value,
.column-percentage {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DataRow-divider {
  background: #ccc;
  height: 1px;
}

.title-text,
.market-value-header,
.allocation-header,
.footer-name,
.footer-value,
.footer-percentage,
.total-name,
.total-value,
.total-percentage {
  flex: 0.5;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hr-divider {
  background: #000;
  height: 2px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.total-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.total-value {
  flex: 0.5;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 400px) {
  .column-name {
    font-size: 10px;
  }
  .title-text,
  .market-value-header,
  .allocation-header,
  .footer-name,
  .footer-value,
  .footer-percentage,
  .total-name,
  .total-value,
  .total-percentage {
    font-size: 10px;
  }
}
